// Generated by Framer (1249b65)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useLocaleInfo, useOnVariantChange, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["IwZXGXKPY", "kOtagQ_vw", "tM94DJCBp"];

const variantClassNames = {IwZXGXKPY: "framer-v-1kavegt", kOtagQ_vw: "framer-v-1fl266c", tM94DJCBp: "framer-v-1hgy2o4"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 69, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transition1 = {damping: 69, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const transition2 = {duration: 0, type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "IwZXGXKPY", "Variant 2": "kOtagQ_vw", "Variant 3": "tM94DJCBp"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, qADpH94GQ: title ?? props.qADpH94GQ ?? "®", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "IwZXGXKPY"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, qADpH94GQ, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "IwZXGXKPY", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onAppear11595ze = activeVariantCallback(async (...args) => {
await delay(() => setVariant("kOtagQ_vw"), 3000)
})

const onAppear1kfzcky = activeVariantCallback(async (...args) => {
await delay(() => setVariant("tM94DJCBp"), 200)
})

const onTap61bxcu = activeVariantCallback(async (...args) => {
setVariant("kOtagQ_vw")
})

useOnVariantChange(baseVariant, {default: onAppear11595ze, kOtagQ_vw: onAppear1kfzcky})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-Os6Dd", classNames)} style={{display: "contents"}}>
<Transition value={transition1}><motion.div {...restProps} className={cx("framer-1kavegt", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"IwZXGXKPY"} ref={ref} style={{...style}} {...addPropertyOverrides({kOtagQ_vw: {"data-framer-name": "Variant 2"}, tM94DJCBp: {"data-framer-name": "Variant 3", onTap: onTap61bxcu}}, baseVariant, gestureVariant)}><Transition value={transition2}><RichText __fromCanvasComponent children={<React.Fragment><motion.h1 style={{"--framer-font-size": "240px", "--framer-line-height": "0.83em", "--framer-text-alignment": "left"}}>®</motion.h1></React.Fragment>} className={"framer-wz82gq"} layoutDependency={layoutDependency} layoutId={"QFh0y2kVR"} style={{"--framer-paragraph-spacing": "0px", rotate: 0}} text={qADpH94GQ} variants={{tM94DJCBp: {rotate: 71}}} verticalAlignment={"top"} withExternalLayout {...addPropertyOverrides({kOtagQ_vw: {children: <React.Fragment><motion.h1 style={{"--framer-font-size": "155px", "--framer-line-height": "0.83em", "--framer-text-alignment": "left"}}>👋</motion.h1></React.Fragment>, text: undefined}, tM94DJCBp: {children: <React.Fragment><motion.h1 style={{"--framer-font-size": "155px", "--framer-line-height": "0.83em", "--framer-text-alignment": "left"}}>👋</motion.h1></React.Fragment>, text: undefined}}, baseVariant, gestureVariant)}/></Transition></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-Os6Dd [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Os6Dd .framer-duai0l { display: block; }", ".framer-Os6Dd .framer-1kavegt { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-Os6Dd .framer-wz82gq { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Os6Dd .framer-1kavegt { gap: 0px; } .framer-Os6Dd .framer-1kavegt > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-Os6Dd .framer-1kavegt > :first-child { margin-left: 0px; } .framer-Os6Dd .framer-1kavegt > :last-child { margin-right: 0px; } }", ".framer-Os6Dd.framer-v-1fl266c .framer-1kavegt { height: 202px; width: 160px; }", ".framer-Os6Dd.framer-v-1fl266c .framer-wz82gq, .framer-Os6Dd.framer-v-1hgy2o4 .framer-wz82gq { height: 150px; order: 0; white-space: pre-wrap; width: 148px; word-break: break-word; word-wrap: break-word; }", ".framer-Os6Dd.framer-v-1hgy2o4 .framer-1kavegt { cursor: pointer; height: 202px; width: 160px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 199
 * @framerIntrinsicWidth 161
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"kOtagQ_vw":{"layout":["fixed","fixed"]},"tM94DJCBp":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"qADpH94GQ":"title"}
 * @framerImmutableVariables false
 */
const FramerbWrMbMH0S: React.ComponentType<Props> = withCSS(Component, css, "framer-Os6Dd") as typeof Component;
export default FramerbWrMbMH0S;

FramerbWrMbMH0S.displayName = "intro nombre";

FramerbWrMbMH0S.defaultProps = {height: 199, width: 161};

addPropertyControls(FramerbWrMbMH0S, {variant: {options: ["IwZXGXKPY", "kOtagQ_vw", "tM94DJCBp"], optionTitles: ["Variant 1", "Variant 2", "Variant 3"], title: "Variant", type: ControlType.Enum}, qADpH94GQ: {defaultValue: "®", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerbWrMbMH0S, [])